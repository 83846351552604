<template>
  <div class="box">
    <span
      v-for="e of pageTabs"
      :key="e.value"
      :class="{ sel: e.value === value }"
      @click="onClick(e)"
      >{{ e.label }}</span
    >
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import _ from 'lodash';
export default {
  inject: ['pageTabs'],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    pageKey() {
      return this.$store.state.screenStore.pageKey;
    },
  },
  methods: {
    ...mapMutations(['screenStore/SET_warningTotal']),
    onClick: _.throttle(
      function (pageTab) {
        if (this.value == pageTab.value) return;
        console.log(this.value + '切换Tab' + pageTab.value);
        /*const current = this.pageTabs.find(item=>item.value===this.value);
if(current){
  this.$bus.$off(`warning-success-${current.prefix}`)
  if(current.prefix=="home"){
    this.pageTabs.forEach((item)=>{
      this.$bus.$off(`warning-success-${item.prefix}`)
    })
  }
}*/
        this['screenStore/SET_warningTotal']({});
        this.$emit('change', pageTab.value);
      },
      500,
      {
        leading: true,
      }
    ),
  },
};
</script>

<style lang="scss" scoped>
$h: 68px;
.box {
  flex: 0 0 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  span {
    display: inline-block;
    text-align: center;
    width: 218px;
    height: 100%;
    font-size: 22px;
    font-weight: bold;
    line-height: $h - 14px;
    background: url('../../../assets/screen/bj/tab.png') center no-repeat;
    background-size: 100% 100%;
  }
  .sel {
    color: #d5bd51;
    background-image: url('../../../assets/screen/bj/tab_sel.png');
  }
}
</style>
